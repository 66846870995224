const screenSizes = {
  smallMobile: 320,
  mobile: 576,
  tablet: 768,
  desktop: 992,
  mediumDesktop: 1200,
  largeDesktop: 1440,
  hugeDesktop: 1666,
  checkoutBreakpoint: 1024,
};

export const { smallMobile, mobile, tablet, desktop, mediumDesktop, largeDesktop, hugeDesktop, checkoutBreakpoint } =
  screenSizes;
