export class ImageHelpers {
  private static addSuffixToImageUrl = function (url: string, suffix: string) {
    const dotIndex = url.lastIndexOf('.');
    if (dotIndex !== -1) {
      const extension = url.substring(dotIndex);
      return url.substring(0, dotIndex) + '-' + suffix + extension;
    }
    return url;
  };

  private static addWidthToImageUrl = function (url: string, width: string, height?: string) {
    const urlParts = url.split('?');
    return (
      urlParts[0] +
      '?width=' +
      width +
      (height ? '&height=' + height : '') +
      (urlParts.length > 1 ? '&' + urlParts[1] : '')
    );
  };

  // https://docs.commercetools.com/api/projects/products#image
  static modifiedCTImageUrl = (imageSrc: string, suffix: string) => {
    if (imageSrc) return ImageHelpers.addSuffixToImageUrl(imageSrc, suffix);
    return undefined;
  };

  static modifiedContentStackImageUrl = (imageSrc: string, width: string, height?: string) => {
    if (imageSrc) return ImageHelpers.addWidthToImageUrl(imageSrc, width, height);
    return undefined;
  };
}
