// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import Toaster from 'components/commercetools-ui/atoms/toaster';
import 'tailwindcss/tailwind.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-loading-skeleton/dist/skeleton.css';
import '../styles/cofe-app.css';
import '../styles/app.scss';
import { sdk } from 'sdk';
import { FrontasticProvider } from 'frontastic';
import useTouchDevice from '../helpers/hooks/useTouchDevice';

function FrontasticStarter({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { isTouchDevice } = useTouchDevice();

  useEffect(() => {
    isTouchDevice && document.body.classList.add('is-touch');
  });

  sdk.configureForNext(router.locale as string);

  return (
    <FrontasticProvider>
      <Component {...pageProps} />
      <Toaster />
    </FrontasticProvider>
  );
}

export default appWithTranslation(FrontasticStarter);
