import { useCallback } from 'react';
import { sdk } from 'sdk';

const usePaypal = () => {
  const openPaypalSession = useCallback(async (isExpress: boolean = false) => {
    const response = await sdk.callAction({
      actionName: 'paypal/openPaypalSession',
      payload: { isExpress },
    });

    const defaultErrorResponse = {
      transactionState: 'ERROR',
      error: {
        reason: 'OTHER_ERROR',
        intent: 'PAYPAL_OTHER',
        message: 'There was an error trying to create your paypal order. Please try again',
      },
    };

    return (response.isError ? defaultErrorResponse : response.data) as any;
  }, []);

  const makePayment = useCallback(async (orderApproval) => {
    const response = await sdk.callAction({
      actionName: 'paypal/makePayment',
      payload: orderApproval,
    });

    const defaultErrorResponse = {
      transactionState: 'ERROR',
      error: {
        reason: 'OTHER_ERROR',
        intent: 'PAYPAL_OTHER',
        message: 'There was an error trying to create your paypal order. Please try again',
      },
    };
    return (response.isError ? defaultErrorResponse : response.data) as any;
  }, []);

  const capturePaypalOrder = useCallback(async (orderId) => {
    const response = await sdk.callAction({
      actionName: 'paypal/capturePaypalOrder',
      payload: { orderId },
    });

    const defaultErrorResponse = {
      transactionState: 'ERROR',
      error: {
        reason: 'OTHER_ERROR',
        intent: 'PAYPAL_CAPTURE',
        message: 'There was an error trying to capture your paypal order. Please try again',
      },
    };

    return (response.isError ? defaultErrorResponse : response.data) as any;
  }, []);

  const submitExpressOrderInformation = useCallback(async (orderData) => {
    const response = await sdk.callAction({
      actionName: 'paypal/submitExpressOrderInformation',
      payload: orderData,
    });

    const defaultErrorResponse = {
      transactionState: 'ERROR',
      error: {
        reason: 'OTHER_ERROR',
        intent: 'PAYPAL_SUBMIT',
        message: 'There was an error trying to save your paypal order. Please try again',
      },
    };

    return (response.isError ? defaultErrorResponse : response.data) as any;
  }, []);

  const setShippingAddress = useCallback(async (paypalUpdate) => {
    const response = await sdk.callAction({
      actionName: 'paypal/setShippingAddress',
      payload: paypalUpdate,
    });

    const defaultErrorResponse = {
      transactionState: 'ERROR',
      error: {
        reason: 'OTHER_ERROR',
        intent: 'PAYPAL_UPDATE',
        message: 'There was an error trying to update your paypal order. Please try again',
      },
    };

    return (response.isError ? defaultErrorResponse : response.data) as any;
  }, []);

  const setShippingMethod = useCallback(async (paypalUpdate) => {
    const response = await sdk.callAction({
      actionName: 'paypal/setShippingMethod',
      payload: paypalUpdate,
    });

    const defaultErrorResponse = {
      transactionState: 'ERROR',
      error: {
        reason: 'OTHER_ERROR',
        intent: 'PAYPAL_UPDATE',
        message: 'There was an error trying to update your paypal order. Please try again',
      },
    };

    return (response.isError ? defaultErrorResponse : response.data) as any;
  }, []);

  return {
    openPaypalSession,
    makePayment,
    capturePaypalOrder,
    submitExpressOrderInformation,
    setShippingAddress,
    setShippingMethod,
  };
};

export default usePaypal;
