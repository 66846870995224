import { appLocales } from './appLocales';
import { routerLocales } from './routerLocales';

export class IMAGE_SOURCE {
  static readonly IMG_UNAVAILABLE_EN = '/images/nkn-product-image-unavailable-en.png';
  static readonly IMG_UNAVAILABLE_FR = '/images/nkn-product-image-unavailable-fr.png';
  static readonly IMG_UNAVAILABLE_SP = '/images/nkn-product-image-unavailable-sp.png';

  static readonly getPerRouterLocale = (locale: string) => {
    switch (locale) {
      case routerLocales['fr-CA']:
        return IMAGE_SOURCE.IMG_UNAVAILABLE_FR;

      case routerLocales['es-MX']:
      case routerLocales['es-419']:
        return IMAGE_SOURCE.IMG_UNAVAILABLE_SP;

      default:
        return IMAGE_SOURCE.IMG_UNAVAILABLE_EN;
    }
  };

  static readonly getPerAppLocale = (locale: string) => {
    switch (locale) {
      case appLocales.FR_CA:
        return IMAGE_SOURCE.IMG_UNAVAILABLE_FR;

      case appLocales.ES_MX:
      case appLocales.ES_LATAM:
        return IMAGE_SOURCE.IMG_UNAVAILABLE_SP;

      default:
        return IMAGE_SOURCE.IMG_UNAVAILABLE_EN;
    }
  };

  static readonly getUnavailableImage = (locale?: string) => {
    if (!locale) {
      return IMAGE_SOURCE.IMG_UNAVAILABLE_EN;
    }

    return appLocales.hasOwnProperty(locale)
      ? IMAGE_SOURCE.getPerAppLocale(locale)
      : IMAGE_SOURCE.getPerRouterLocale(locale);
  };
}
