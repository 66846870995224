import React, { FC, useMemo, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import Button from 'components/commercetools-ui/atoms/button';
import { IMAGE_SOURCE } from 'helpers/constants/imageSources';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import useVariantWithDiscount from 'helpers/hooks/useVariantWithDiscount';
import { desktop } from 'helpers/utils/screensizes';
import { useCart } from 'frontastic';
import Image from 'frontastic/lib/image';
import styles from './ProductTilePDP.module.scss';
import { buttonTextConfig } from './types/buttonTextConfig';
import { getVariantAddToCartLimit, getVariantCartQuantity } from '../../../../../helpers/productHelpers';
import { getLocalizationInfo } from '../../../../../project.config';
import { TLocalizationInfo } from '../../../../../types/TLocalizationInfo';
import { EAddToCartStatus, TNikonProduct, TNikonVariant } from '../../../../../types/TNikonProduct';
import { View } from '../product-list/context/types';

interface ProductTileProps {
  product: TNikonProduct;
  onClick?: () => void;
  isSearchResult?: boolean;
  disableQuickView?: boolean;
  disableWishlistButton?: boolean;
  disableVariants?: boolean;
  onAddToCart?: (product: TNikonProduct, variant: TNikonVariant, count: number) => void;
  view?: View;
}

const ProductTile: FC<ProductTileProps> = ({
  product,
  isSearchResult = false,
  disableVariants = false,
  onAddToCart,
  view = 'grid',
}) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const [isDesktopSize] = useMediaQuery(desktop);
  const { addItem, data: cartData } = useCart();
  const [added, setAdded] = useState(false);
  const [isShowAddToCartError, setIsShowAddToCartError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const localizationInfo: TLocalizationInfo = getLocalizationInfo(router.locale || router.defaultLocale);
  const [selectedVariant, setSelectedVariant] = useState<TNikonVariant>(
    (useVariantWithDiscount(product.variants) as TNikonVariant) ?? product?.variants?.[0],
  );
  const isArchived = Boolean(product.isArchived);
  const imageUnavailable = IMAGE_SOURCE.getUnavailableImage(router.locale);
  const variantCartQuantity = getVariantCartQuantity({ cart: cartData, sku: selectedVariant.sku });
  const variantAddToCartLimit = getVariantAddToCartLimit(selectedVariant, localizationInfo.appLocale);
  const userCanAddMoreUnitsToCart = variantCartQuantity < variantAddToCartLimit;
  const userCanBuy = !isArchived && selectedVariant.attributes.isAvailableForSale && userCanAddMoreUnitsToCart;

  const addToCartStatus =
    (selectedVariant.addToCartStatus as unknown as EAddToCartStatus) || EAddToCartStatus.ADD_TO_CART;

  const handleAddToCart = async () => {
    setIsShowAddToCartError(false);

    if (userCanBuy) {
      setLoading(true);

      const cartResponse = await addItem(selectedVariant, 1);

      setLoading(false);
      setIsShowAddToCartError(!cartResponse.cartId);

      if (cartResponse.cartId) {
        setAdded(true);

        setTimeout(() => {
          setAdded(false);
        }, 5000);

        if (onAddToCart) {
          onAddToCart(product, selectedVariant, 1);
        }
      }
    }
  };

  const productUrl = useMemo(() => {
    if (!product._url) {
      return '#';
    }

    if (isSearchResult) {
      return `${product._url}?sr=1`;
    }

    return product._url;
  }, [product._url, isSearchResult]);

  return (
    <div
      className={`${styles.tileBody} ${isDesktopSize && styles.hoverBody} ${added && styles.borderAdd} ${
        view === 'list' && styles.listItem
      }`}
    >
      <div className="relative">
        <NextLink href={productUrl}>
          <a>
            <div className="relative w-full">
              <div className="relative bg-white p-8 md:p-16">
                <div className="relative block w-full" style={{ paddingBottom: '122%' }}>
                  <Image
                    src={selectedVariant.images?.[0] || imageUnavailable}
                    onError={({ currentTarget }) => {
                      currentTarget.src = imageUnavailable;
                    }}
                    suffix="medium"
                    alt={product.name}
                    objectFit="contain"
                    objectPosition="center"
                    className="w-full rounded-sm group-hover:opacity-75 md:p-16"
                  />
                </div>
              </div>
            </div>
          </a>
        </NextLink>
      </div>
      <div>
        <NextLink href={productUrl}>
          <a className="text-decoration-none text-black">
            <div>
              <div className=" t block max-w-[80%] overflow-hidden text-ellipsis whitespace-pre ">{product?.name}</div>
              {!disableVariants && (
                <div className=" flex flex-wrap items-center gap-4 md:mt-12">
                  {product?.variants.map((variant, index) => (
                    <span
                      key={index}
                      className={`block cursor-pointer rounded-full border p-[6px] ${
                        variant.sku !== selectedVariant.sku ? 'border-neutral-300' : 'border-neutral-500'
                      }`}
                      style={{ backgroundColor: variant.attributes?.color || variant.attributes?.finish }}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedVariant(variant as TNikonVariant);
                      }}
                    ></span>
                  ))}
                </div>
              )}

              <div data-testid="price" className={styles['price-container']}>
                {isArchived && formatProductMessage({ id: 'productItem.archived', defaultMessage: 'Archived' })}

                {!isArchived && !selectedVariant.discountedPrice?.centAmount && (
                  <span className={styles.price} data-testid="regular-price">
                    {CurrencyHelpers.formatForCurrency(selectedVariant.price, router.locale)}
                  </span>
                )}

                {!isArchived && selectedVariant.discountedPrice?.centAmount && (
                  <>
                    <span className={styles.price} data-testid="discount-price">
                      {CurrencyHelpers.formatForCurrency(selectedVariant.discountedPrice, router.locale)}
                    </span>
                    &nbsp;
                    <span className={styles['oldPrice']} data-testid="old-price">
                      {CurrencyHelpers.formatForCurrency(selectedVariant.price, router.locale)}
                    </span>
                  </>
                )}

                {!isArchived && selectedVariant.discountedPrice && (
                  <span data-testid="you-save-message" className={styles['save-money']}>
                    {formatCartMessage({ id: 'total.discount.title', defaultMessage: 'You Save' })}{' '}
                    {CurrencyHelpers.formatForCurrency(
                      CurrencyHelpers.subtractCurrency(selectedVariant.price, selectedVariant.discountedPrice),
                      router.locale,
                    )}
                  </span>
                )}

                {addToCartStatus !== EAddToCartStatus.OUT_OF_STOCK && !userCanAddMoreUnitsToCart && (
                  <p className={styles['max-quantity-meet']}>
                    {formatProductMessage({
                      id: 'maxQuantityMeet',
                      defaultMessage: 'Maximum order quantity has been met.',
                    })}
                  </p>
                )}
              </div>
            </div>
          </a>
        </NextLink>
      </div>
      <div className={styles.addToCart}>
        <Button
          data-testid="button"
          size="xs"
          className={`${added && 'btn-green'} btn-yellow `}
          variant="primary"
          loading={loading}
          disabled={addToCartStatus === EAddToCartStatus.OUT_OF_STOCK || loading || !userCanAddMoreUnitsToCart}
          onClick={() => !added && handleAddToCart()}
        >
          {!added && formatCartMessage(buttonTextConfig[addToCartStatus as keyof typeof buttonTextConfig])}
          {added && formatCartMessage({ id: 'cart.itemAdded', defaultMessage: 'Item added' })}
        </Button>
      </div>

      {isShowAddToCartError && (
        <p className={styles['add-to-cart-error']}>
          {formatCartMessage({
            id: 'genericAddToCartError',
            defaultMessage: 'We are unable to add to cart. Please try again.',
          })}
        </p>
      )}
    </div>
  );
};

export default ProductTile;
