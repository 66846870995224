import { EAddToCartStatus } from '../../../../../../types/TNikonProduct';

export const buttonTextConfig = {
  [EAddToCartStatus.ADD_TO_CART]: {
    id: 'productItem.addToCart',
    defaultMessage: 'ADD TO CART',
  },
  [EAddToCartStatus.PREORDER]: {
    id: 'productItem.preorder',
    defaultMessage: 'PREORDER',
  },
  [EAddToCartStatus.BACKORDER]: {
    id: 'productItem.addToCart',
    defaultMessage: 'ADD TO CART',
  },
  [EAddToCartStatus.OUT_OF_STOCK]: {
    id: 'productItem.outOfStock',
    defaultMessage: 'OUT OF STOCK',
  },
};
