const localizationMapper = {
  'en-US': {
    locale: 'en_US@USD',
    appLocale: 'en_US',
    language: 'en',
    useCurrencyInLocale: true,
    currency: 'USD',
    currencyCode: '$',
    countryCode: 'US',
    countryName: 'United States of America',
  },
  'en-CA': {
    locale: 'en_CA@CAD',
    appLocale: 'en_CA',
    language: 'en',
    useCurrencyInLocale: true,
    currency: 'CAD',
    currencyCode: '$',
    countryCode: 'CA',
    countryName: 'Canada',
  },
  'fr-CA': {
    locale: 'fr_CA@CAD',
    appLocale: 'fr_CA',
    language: 'fr',
    useCurrencyInLocale: true,
    currency: 'CAD',
    currencyCode: '$',
    countryCode: 'CA',
    countryName: 'Canada',
  },
  'es-MX': {
    locale: 'es_MX@MXN',
    appLocale: 'es_MX',
    language: 'es',
    useCurrencyInLocale: true,
    currency: 'MXN',
    currencyCode: '$',
    countryCode: 'MX',
    countryName: 'Mexico',
  },
  'es-419': {
    locale: 'es_419@COP',
    appLocale: 'es_419',
    language: 'es',
    useCurrencyInLocale: true,
    currency: 'COP',
    currencyCode: '$',
    countryCode: '419',
    countryName: 'Latin America',
  },
  'pt-BR': {
    locale: 'pt_BR@BRL',
    appLocale: 'pt_BR',
    language: 'pt',
    useCurrencyInLocale: true,
    currency: 'BRL',
    currencyCode: '$',
    countryCode: 'BR',
    countryName: 'Brazil',
  },
};

const getLocalizationInfo = (locale) => {
  if (!locale) {
    locale = 'en-US';
  }

  if (!(locale in localizationMapper)) {
    console.warn(
      `Invalid locale ${locale} provided. Possible values are ${Object.keys(localizationMapper).join(', ')}`,
    );

    return localizationMapper.en;
  }

  return localizationMapper[locale];
};

module.exports = { getLocalizationInfo };
