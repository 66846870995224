import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { getLocalizationInfo } from 'project.config';

const useI18n = () => {
  const router = useRouter();

  const locale = router?.locale;

  const defaultLocale = router?.defaultLocale;

  const config = useMemo(() => getLocalizationInfo(locale), [locale]);

  const country = useMemo(() => config?.countryCode, [config]);

  const currency = useMemo(() => config?.currency, [config]);

  const currencySymbol = useMemo(() => config?.currencyCode, [config]);

  return { country, currency, currencySymbol, defaultLocale, locale };
};

export default useI18n;
