import React, { useCallback, useContext, useState } from 'react';
import { useRouter } from 'next/router';
import { Col, Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Link from 'components/commercetools-ui/atoms/link';
import ProductSlider from 'components/commercetools-ui/organisms/product/product-slider';
import { IMAGE_SOURCE } from 'helpers/constants/imageSources';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import useScrollBlock from 'helpers/hooks/useScrollBlock';
import { TNikonProduct, TNikonVariant } from 'types/TNikonProduct';
import { useCart } from 'frontastic';
import styles from './AddToCartOverlay.module.scss';
import { AddToCartOverlayContextShape } from './types';
import { getAppropriateSkuName } from '../../helpers/productHelpers';
import { ImageHelpers } from 'helpers/imageHelpers';

const AddToCartOverlayContext = React.createContext<AddToCartOverlayContextShape>({
  show() {},
  showRecentProduct() {},
  hide() {},
});

const AddToCartOverlayProvider: React.FC = ({ children }) => {
  const router = useRouter();

  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  const { blockScroll } = useScrollBlock();

  const { transaction, data } = useCart();

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);
  const handleShow = () => setIsOpen(true);
  const [variant, setVariant] = useState<TNikonVariant>();
  const [recentCartVariants, setRecentCartVariants] = useState<TNikonVariant[]>();
  const imageUnavailable = IMAGE_SOURCE.getUnavailableImage(router.locale);

  const show = useCallback(
    (product: TNikonProduct, variant: TNikonVariant, softBundleVariantSku?: string, count?: number) => {
      setVariant(variant);

      const productName = getAppropriateSkuName(product.name, variant);
      let softBundledVariant;

      if (softBundleVariantSku) {
        softBundledVariant = variant.attributes?.bundleWithList?.find((item) => item.sku === softBundleVariantSku);
      }

      setRecentCartVariants([{ ...variant, productName }, ...(softBundledVariant ? [softBundledVariant] : [])]);

      if (product?.variants) blockScroll(true);
      handleShow();
    },
    [blockScroll],
  );

  const hide = useCallback(() => {
    blockScroll(false);
    handleClose();
  }, [blockScroll]);

  const showRecentProduct = useCallback(
    (product: TNikonProduct, variant: TNikonVariant) => {
      const productName = getAppropriateSkuName(product.name, variant);

      if (variant?.attributes?.bundleWithList) {
        setRecentCartVariants([
          { ...variant, productName },
          ...variant?.attributes?.bundleWithList,
          ...(recentCartVariants || []),
        ]);
      } else {
        setRecentCartVariants([{ ...variant, productName }, ...(recentCartVariants || [])]);
      }

      if (product?.variants) blockScroll(true);
    },
    [blockScroll],
  );

  const priceExtract = (sku: string, recentVariant: TNikonVariant) => {
    const lineItem = data?.lineItems?.find((item) => item.variant.sku === sku);

    if (lineItem && lineItem.totalPriceWithProration) {
      const pricePerItem = Number(lineItem.totalPriceWithProration) / (lineItem.count || 1);
      return CurrencyHelpers.formatForCurrency(pricePerItem, router.locale, 'USD', 2);
    }

    if (lineItem && lineItem.totalPrice) {
      const adjustedTotalPrice = {
        ...lineItem.totalPrice, //@ts-ignore
        centAmount: lineItem.totalPrice / lineItem.count,
      };

      return CurrencyHelpers.formatForCurrency(adjustedTotalPrice, router.locale);
    }

    const fallbackPrice = recentVariant?.discountedPrice || recentVariant?.price || {};
    return CurrencyHelpers.formatForCurrency(fallbackPrice, router.locale);
  };

  return (
    <>
      <Modal show={isOpen} onHide={handleClose} className={`${styles['add-to-cart-overlay']}`} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-center">
              {`${
                Boolean(recentCartVariants?.length && recentCartVariants?.length > 1)
                  ? ` ${recentCartVariants?.length} Items`
                  : `${recentCartVariants?.length} Item`
              }  `}
              {formatProductMessage({ id: 'cart.added', defaultMessage: `Added to Your Cart` })}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {recentCartVariants?.map((recentVariant, index) => (
              <Row className={styles.content} key={`${recentVariant.id}-recent-variant-${index}`}>
                <Col>
                  <div className={styles[`photo-container`]}>
                    <img
                      data-testid="photo"
                      src={ImageHelpers.modifiedCTImageUrl(recentVariant?.images?.[0], 'medium') || imageUnavailable}
                      loading="lazy"
                      onError={({ currentTarget }) => {
                        currentTarget.src = imageUnavailable;
                      }}
                      alt={`${recentVariant?.productName}-image`}
                    />
                  </div>
                </Col>
                <Col>
                  <Row>
                    <Col sm={8}>
                      <p className={styles.name}>{recentVariant?.productName ?? recentVariant?.attributes?.title}</p>
                      <p className={styles.sku}>
                        {' '}
                        {formatProductMessage({ id: 'cart.productNumber', defaultMessage: `Product` })}{' '}
                        {recentVariant?.sku}
                      </p>
                    </Col>
                    <Col className="text-right" sm={4}>
                      <p className={styles.currentPrice}>
                        {Boolean(data?.lineItems) && recentVariant.sku
                          ? priceExtract(recentVariant.sku, recentVariant)
                          : CurrencyHelpers.formatForCurrency(
                              recentVariant?.discountedPrice ?? recentVariant?.price ?? {},
                              router.locale,
                            )}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}

            <Row className={styles.subTotalContainer}>
              <Col className="text-center">
                <p className={styles.subTotal}>
                  {formatProductMessage({ id: 'bought.subtotal', defaultMessage: 'Your order subtotal is' })}{' '}
                  {CurrencyHelpers.formatForCurrency(transaction.total, router.locale)}
                </p>
                <Link link="/cart" onClick={hide}>
                  <Button className="btn btn-yellow">
                    {formatCartMessage({ id: 'cart.go', defaultMessage: 'PROCEED TO CHECKOUT' })}
                  </Button>
                </Link>
              </Col>
            </Row>

            {variant?.attributes?.addToCartAccessories && (
              <div className={`bg-white py-24 md:block`}>
                <ProductSlider
                  clearDefaultWrapperStyles
                  products={variant?.attributes?.addToCartAccessories}
                  title={formatProductMessage({ id: 'bought.together', defaultMessage: 'Recommended Accessories :' })}
                  titleVariant="xs"
                  disableProductQuickView
                  disableProductWishlistButton
                  disableProductVariants
                  onProductClick={hide}
                  onAddToCart={showRecentProduct}
                  classNames={{ title: 'text-center' }}
                />
              </div>
            )}
          </Container>
        </Modal.Body>
      </Modal>
      <AddToCartOverlayContext.Provider value={{ show, hide, showRecentProduct }}>
        {children}
      </AddToCartOverlayContext.Provider>
    </>
  );
};

export default AddToCartOverlayProvider;

export const useAddToCartOverlay = () => useContext(AddToCartOverlayContext);
