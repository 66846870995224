import React from 'react';
import {
  GTM_CONTAINER_ID_EN_CA,
  GTM_CONTAINER_ID_EN_US,
  GTM_CONTAINER_ID_ES_419,
  GTM_CONTAINER_ID_ES_MX,
  GTM_CONTAINER_ID_FR_CA,
  GTM_CONTAINER_ID_PR_BR,
} from '../../../helpers/constants/googleTagManager';

interface GTMSnippetProps {
  locale?: string;
}

export const sendGTMEvent = (data: object) => {
  if (window.dataLayer) {
    window.dataLayer.push(data);
  } else {
    console.warn('GTMSnippet: GTM dataLayer does not exist');
  }
};

const GTMSnippet = ({ locale }: GTMSnippetProps) => {
  const gtmMap = {
    'en-US': GTM_CONTAINER_ID_EN_US,
    'en-CA': GTM_CONTAINER_ID_EN_CA,
    'fr-CA': GTM_CONTAINER_ID_FR_CA,
    'es-MX': GTM_CONTAINER_ID_ES_MX,
    'es-419': GTM_CONTAINER_ID_ES_419,
    'pt-BR': GTM_CONTAINER_ID_PR_BR,
  };
  // @ts-ignore
  const GTM_CONTAINER_ID = gtmMap[locale || 'en-US'];
  if (Boolean(GTM_CONTAINER_ID)) {
    return (
      <>
        <script
          type="text/javascript"
          id="gtm-script"
          dangerouslySetInnerHTML={{
            __html: `
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${GTM_CONTAINER_ID}');
    `,
          }}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default GTMSnippet;
