export const BRANDS = {
  NIKON: 'Nikon',
};

export const USER_CATEGORY = {
  LOGGED_IN: 'Logged-In',
  GUEST: 'Guest',
};

// empty values are dynamic
export const PAGE_VIEW_EVENTS = {
  HOME: {
    NAME: 'Homepage',
    CATEGORY: 'Homepage',
    TYPE: 'Homepage',
  },
  CATEGORY_LANDING: {
    NAME: '',
    CATEGORY: '',
    TYPE: 'Category Landing Page',
  },
  PLP: {
    NAME: '',
    CATEGORY: '',
    TYPE: 'Product Listing Page',
  },
  PDP: {
    NAME: '',
    CATEGORY: '',
    TYPE: 'Product Detail Page',
  },
  PRODUCT_OVERVIEW: {
    NAME: '',
    CATEGORY: '',
    TYPE: 'Product Overview',
  },
  CART: {
    NAME: 'Cart',
    CATEGORY: 'Cart',
    TYPE: 'Cart',
  },
  CHECKOUT: {
    NAME: 'Checkout',
    CATEGORY: 'Checkout',
    TYPE: 'Checkout',
  },
  SEARCH: {
    NAME: 'Search',
    CATEGORY: 'Search',
    TYPE: 'Search',
  },
  THANK_YOU: {
    NAME: 'Thank You',
    CATEGORY: 'Order',
    TYPE: 'Order',
  },
  LE_ARTICLE: {
    NAME: '',
    CATEGORY: '',
    TYPE: 'Learn and Explore - Article',
  },
  LE_CATEGORY: {
    NAME: '',
    CATEGORY: '',
    TYPE: 'Learn and Explore - Category',
  },
  LE_COLLECTION: {
    NAME: '',
    CATEGORY: 'Learn and Explore - Collection',
    TYPE: 'Learn and Explore - Collection',
  },
  LE_GLOSSARY: {
    NAME: 'Learn and Explore - Glossary',
    CATEGORY: 'Learn and Explore - Glossary',
    TYPE: 'Learn and Explore - Glossary',
  },
  LE_HOMEPAGE: {
    NAME: 'Learn and Explore - Homepage',
    CATEGORY: 'Learn and Explore - Homepage',
    TYPE: 'Learn and Explore - Homepage',
  },
  LE_SEARCH: {
    NAME: 'Learn and Explore - Search',
    CATEGORY: 'Learn and Explore - Search',
    TYPE: 'Learn and Explore - Search',
  },
  AMBASSADORS: {
    NAME: 'Nikon Ambassadors',
    CATEGORY: 'Nikon Ambassadors',
    TYPE: 'Nikon Ambassadors',
  },
  MENTORS: {
    NAME: 'Nikon Mentors',
    CATEGORY: 'Nikon Mentors',
    TYPE: 'Nikon Mentors',
  },
  PRESS_ROOM_ARTICLE: {
    NAME: '',
    CATEGORY: '',
    TYPE: 'Press Room - Article',
  },
  PRESS_ROOM_LIST: {
    NAME: 'Press Room - List',
    CATEGORY: 'Press Room - List',
    TYPE: 'Press Room - List',
  },
  SERVICE_ADVISORY_ARTICLE: {
    NAME: '',
    CATEGORY: '',
    TYPE: 'Service Advisory - Article',
  },
  SERVICE_ADVISORY_LIST: {
    NAME: 'Service Advisory List',
    CATEGORY: 'Service Advisory List',
    TYPE: 'Service Advisory - List',
  },
  TRADE_IN: {
    NAME: 'Trade In',
    CATEGORY: 'Trade In',
    TYPE: 'Trade In',
  },
  TRADE_IN_FORM: {
    NAME: 'Trade In Form',
    CATEGORY: 'Trade In Form',
    TYPE: 'Trade In Form',
  },
  STATIC_PAGE: {
    NAME: '',
    CATEGORY: '',
    TYPE: 'Static Page',
  },
  ERROR: {
    NAME: 'Error',
    CATEGORY: 'Error',
    TYPE: 'Error',
  },
  ORDER_HISTORY: {
    NAME: 'Order History',
    CATEGORY: 'Order History',
    TYPE: 'Order History',
  },
  ORDER_STATUS: {
    NAME: 'Order Status',
    CATEGORY: 'Order Status',
    TYPE: 'Order Status',
  },
  SHIPPING_LABEL: {
    NAME: 'Shipping Label',
    CATEGORY: 'Shipping Label',
    TYPE: 'Shipping Label',
  },
};
