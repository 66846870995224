import { useCallback } from 'react';
import ccType from 'credit-card-type';
import {
  ContextToken,
  PaymentFormData,
  SavedPayment,
  PayerAuthenticationSetup,
  PayerAuthenticationEnrollment,
  PayerAuthenticationValidation,
} from 'types/payment';
import useAccount from '../useAccount';
import { sdk } from 'sdk';
import { LineItem } from '@commercetools/frontend-domain-types/cart/LineItem';
import { ICybersourceDeviceFingerprint } from '../../../../../nikonstore/types/cart/ICybersourceDeviceInfo';

const useCybersource = () => {
  const { account } = useAccount();

  const getSavedPaymentMethods = async () => {
    if (!account) return;

    const response = await sdk.callAction<SavedPayment[]>({
      actionName: 'cybersource/getPaymentMethods',
    });

    return (response.isError ? {} : response.data) as SavedPayment[];
  };

  const updateCybersourcePaymentMethod = useCallback(
    async (
      data: PaymentFormData,
      paymentId: string,
      cartData: LineItem[],
      fingerprint: ICybersourceDeviceFingerprint,
      cybersourceToken: string,
    ) => {
      const year = new Date().getFullYear();
      const firstTwoDigitsOfYear = String(Math.floor(year / 100));
      const expirationYear = firstTwoDigitsOfYear.concat(data.card.expirationYear);

      const payload = {
        default: data.default,
        billingAddress: {
          ...data.billingAddress,
          administrativeArea: data.billingAddress.state,
          locality: data.billingAddress.city,
        },
        card: {
          ...data.card,
          expirationMonth: data.card.expirationMonth.padStart(2, '0'),
          expirationYear,
          number: data.card.number.replaceAll(' ', ''),
          type: ccType(data.card.number)[0]?.type?.replaceAll('-', '_').toUpperCase(),
        },
      };

      const addNewPaymentRes = await sdk.callAction<SavedPayment>({
        actionName: 'cybersource/createPaymentMethodWithToken',
        payload: {
          ...payload,
          cartData: cartData,
          fingerprintSessionId: fingerprint.fingerprintSessionId,
          cyberSourceToken: cybersourceToken,
        },
      });

      if (addNewPaymentRes.isError) {
        return {} as SavedPayment;
      } else {
        const deletePaymentRes = await sdk.callAction({
          actionName: 'cybersource/deletePaymentMethod',
          payload: {
            id: paymentId,
            ...payload,
          },
        });

        if (deletePaymentRes.isError) {
          return {} as SavedPayment;
        }
      }

      return addNewPaymentRes.data as SavedPayment;
    },
    [],
  );

  const setDefault = async (payment: SavedPayment) => {
    const response = await sdk.callAction<SavedPayment>({
      actionName: 'cybersource/setDefaultPaymentMethod',
      payload: {
        ...payment,
        default: true,
      },
    });

    return (response.isError ? {} : response.data) as SavedPayment;
  };

  const removePaymentMethod = async (payment: SavedPayment) => {
    const response = await sdk.callAction({
      actionName: 'cybersource/deletePaymentMethod',
      payload: payment,
    });

    return (response.isError ? {} : response.data) as SavedPayment;
  };

  const setupPayerAuthentication = async () => {
    const response = await sdk.callAction({
      actionName: 'cybersource/setupPayerAuthentication',
    });

    return (response.isError ? {} : response.data) as PayerAuthenticationSetup;
  };

  const enrollPayerAuthentication = async () => {
    const response = await sdk.callAction({
      actionName: 'cybersource/enrollPayerAuthentication',
    });

    return (response.isError ? {} : response.data) as PayerAuthenticationEnrollment;
  };

  const getContextToken = useCallback(async () => {
    const response = await sdk.callAction({
      actionName: 'cybersource/getContextToken',
    });

    return (response.isError ? undefined : response.data) as ContextToken;
  }, []);

  return {
    getSavedPaymentMethods,
    updateCybersourcePaymentMethod,
    setDefault,
    setupPayerAuthentication,
    enrollPayerAuthentication,
    removePaymentMethod,
    getContextToken,
  };
};

export default useCybersource;
