import { appLocales } from './constants/appLocales';
import { CurrencyHelpers } from './currencyHelpers';
import { FormatMessageParams } from './hooks/useFormat';
import { Cart, LineItem } from '../types/cart';
import { EAddToCartStatus, TNikonProduct, TNikonVariant } from '../types/TNikonProduct';
import { TProductByPosition } from '../types/TProductByPosition';

export const getVariantLowestPrice = (variants: TNikonVariant[]): TNikonVariant =>
  variants.reduce((prev, curr) => {
    if (!prev.price?.centAmount || prev.attributes.dealerExclusive) {
      return curr;
    }

    if (!curr.price?.centAmount || curr.attributes.dealerExclusive) {
      return prev;
    }

    return curr.price.centAmount < prev.price.centAmount ? curr : prev;
  }, variants[0]);

export const getIsDisplayProductFeatures = ({
  product,
  variant,
  locale,
}: {
  product: TNikonProduct;
  variant: TNikonVariant;
  locale: string;
}) =>
  !Boolean(product.isArchived) &&
  variant?.attributes?.suppliedProducts?.length &&
  ![appLocales.PT_BR, appLocales.EN_CA, appLocales.FR_CA].includes(locale);

export const getVariantCartQuantity = ({ cart, sku }: { cart?: Cart; sku: string }) => {
  if (cart === undefined) {
    return 0;
  }

  const lineItem = cart.lineItems?.find(({ variant }) => variant.sku === sku);

  return lineItem?.count || 0;
};

/**
 * This function returns the limit of units that the user can buy per variant
 * To understand the logic please visit https://nikoninc.atlassian.net/wiki/spaces/RD/pages/102694930/Product+Inventory#Matrix
 * */
export const getVariantAddToCartLimit = (variant: TNikonVariant, locale: string) => {
  const { addToCartStatus, attributes, availableQuantity, preBackorderChannel } = variant;

  if (addToCartStatus === EAddToCartStatus.OUT_OF_STOCK) {
    return 0;
  }

  const variantQuantityAvailable =
    addToCartStatus === EAddToCartStatus.ADD_TO_CART ? availableQuantity : preBackorderChannel?.availableQuantity || 0;
  const qtyMax = attributes.purchaseLimit || locale === appLocales.EN_US ? 10 : variantQuantityAvailable;

  return Math.min(variantQuantityAvailable, qtyMax);
};

export const showPreBackOrderMessage = ({
  variant,
  count,
  status,
  locale,
}: {
  variant: TNikonVariant;
  count: number;
  status: EAddToCartStatus;
  locale: string;
}) => {
  return variant?.addToCartStatus === status && getVariantAddToCartLimit(variant, locale) > count;
};

export const filterVariantsWithNoStock = (variants: TNikonVariant[], checkInBackPreInventory = false) =>
  variants.reduce((accVariants: TNikonVariant[], currVariant) => {
    if (
      currVariant.availableQuantity > 0 ||
      (checkInBackPreInventory && currVariant.preBackorderChannel?.availableQuantity)
    ) {
      return [...accVariants, currVariant];
    }

    return accVariants;
  }, []);

export const filterVariantsNotAvailableForSale = (variants: TNikonVariant[]) =>
  variants.reduce((accVariants: TNikonVariant[], currVariant) => {
    if (currVariant.attributes.isAvailableForSale === undefined || currVariant.attributes.isAvailableForSale) {
      return [...accVariants, currVariant];
    }

    return accVariants;
  }, []);

export const getVariantBySkuOnAProduct = (product: TNikonProduct | undefined, sku: string) =>
  product?.variants?.find((variant) => variant.sku === sku) as TNikonVariant;

export const searchVariantInProducts = (products: TNikonProduct[], sku: string): TNikonVariant | undefined =>
  products.reduce((variant: TNikonVariant | undefined, currProduct) => {
    return getVariantBySkuOnAProduct(currProduct, sku) || variant;
  }, undefined);

export const getProductByVariantSku = (products: TNikonProduct[], sku: string) =>
  products.find((product) => getVariantBySkuOnAProduct(product, sku));

export const getVariantLowerPrice = (variants: TNikonVariant[]): TNikonVariant =>
  variants
    .filter((variant) => variant.price?.centAmount)
    .reduce((prev, curr) => {
      if (!prev) {
        return curr;
      }

      const prevBasePrice = prev.price.custom?.fields?.basePrice?.centAmount || prev.price.centAmount || 0;
      const prevDiscountedPrice = prev.price.custom?.fields?.basePrice?.centAmount
        ? prev.discountedPrice?.centAmount // This is when the product have both discounts configured, a discount price and a marketing copy discount
          ? prev.discountedPrice?.centAmount
          : prev.price.centAmount
        : prev.discountedPrice?.centAmount || 0;

      const currBasePrice = curr.price.custom?.fields?.basePrice?.centAmount || curr.price.centAmount || 0;
      const currDiscountedPrice = curr.price.custom?.fields?.basePrice?.centAmount
        ? curr.discountedPrice?.centAmount // This is when the product have both discounts configured, a discount price and a marketing copy discount
          ? curr.discountedPrice?.centAmount
          : curr.price.centAmount
        : curr.discountedPrice?.centAmount || 0;

      const prevPrice =
        prevDiscountedPrice && prevDiscountedPrice > prevBasePrice ? prevDiscountedPrice : prevBasePrice;
      const currPrice =
        currDiscountedPrice && currDiscountedPrice > currBasePrice ? currDiscountedPrice : currBasePrice;

      return prevPrice < currPrice ? prev : curr;
    }, variants[0]);

export const getAppropriateSkuName = (productName: string, variant: TNikonVariant) => {
  const isSingleProduct = productName === variant?.attributes?.title;
  const isColor = Boolean(variant?.attributes?.color);
  const isKit = Boolean(variant?.attributes?.kitItems?.length);

  if (isSingleProduct || (!isColor && !isKit)) {
    return productName;
  }

  if (isColor && !isKit) {
    return `${productName} ${variant?.attributes?.color?.label}`;
  }

  if (isColor && isKit) {
    return `${productName} ${variant?.attributes?.title} ${variant?.attributes?.color?.label}`;
  }

  if (!isColor && isKit) {
    return `${productName} ${variant?.attributes?.title}`;
  }
};

export const isProductDiscountWithVariables = (discountDescription: string) =>
  /(\{\})|(\{([^}]+(.*?))\})/g.exec(discountDescription) !== null;

export const getProductDiscountsByPosition = (discountDescription: string): TProductByPosition => {
  if (!Boolean(discountDescription)) {
    return {
      brow: '',
      hero: '',
      redBadge: '',
      autoApply: '',
    };
  }

  const splitter = /(\{\})|(\{([^}]+(.*?))\})/;

  // Case text without braces
  if (!isProductDiscountWithVariables(discountDescription)) {
    return {
      brow: '',
      hero: discountDescription,
      redBadge: '',
      autoApply: '',
    };
  }

  const whiteSpacesBetweenBraces = /\s+(?=[^\}]*\{)/g;
  const bracesRemover = /{(.*)}/;

  const discounts = discountDescription
    .replaceAll(whiteSpacesBetweenBraces, '') // remove white spaces
    .split(splitter) // split all possible variables
    .filter((text) => text) // filter empty values
    .filter((text) => text.match(bracesRemover) !== null) // filter values that has not braces
    .map((text) => (text.match(bracesRemover) ? text.match(bracesRemover)?.pop() : text)); // extract the text from braces values

  return {
    brow: discounts[0] || '',
    hero: discounts[1] || '',
    redBadge: discounts[2] || '',
    autoApply: discounts[3] || '',
  };
};

export const getProductDiscountVarsFromDiscountTextArray = (productDiscounts: string[]) =>
  productDiscounts.filter((discountText) => Boolean(discountText && isProductDiscountWithVariables(discountText)))[0] ||
  '';

const getDiscountTextsInformation = (discountTexts?: string[]) => {
  const filteredDiscountsText = discountTexts?.filter(Boolean);
  const structuredTextPattern = /\{.+?\}/;

  const cartDiscountTexts = filteredDiscountsText?.filter((text) => !structuredTextPattern.test(text)) || [];

  const productDiscountTexts =
    filteredDiscountsText
      ?.filter((text) => structuredTextPattern.test(text))
      .map((text) => {
        const parts = (text.match(/\{(.*?)\}/g) || []).map((part) => part.replace(/[{}]/g, ''));
        return {
          brow: parts[0],
          hero: parts[1],
          redBadge: parts[2],
          autoApply: parts[3],
        };
      }) || [];

  return { cartDiscountTexts, productDiscountTexts };
};

export const getRedBadgePromoMessage = (
  { custom, discountTexts, discounts }: LineItem,
  formatMessage: ({ id, defaultMessage, values }: Omit<FormatMessageParams, 'name'>) => string,
  currency: string,
) => {
  const proratedPromoAmount = custom?.fields?.proratedPromoAmount || 0;
  const { cartDiscountTexts, productDiscountTexts } = getDiscountTextsInformation(discountTexts);

  const productRedBadgeTexts = productDiscountTexts
    .filter(({ redBadge }) => !!redBadge)
    .map(({ redBadge }) => redBadge);

  let promoName;
  let promoMessage;
  let amount = '';

  if (discountTexts && discountTexts.length > 1) {
    promoName = formatMessage({
      id: 'total.promotions.title',
      defaultMessage: 'Promotions',
    });
  } else {
    promoName =
      cartDiscountTexts?.[0] ||
      productRedBadgeTexts?.[0] ||
      discounts?.[0]?.name ||
      formatMessage({
        id: 'individual.discount',
        defaultMessage: 'Promotion',
      });
  }

  if (proratedPromoAmount > 0) {
    amount = CurrencyHelpers.formatForCurrency({
      centAmount: proratedPromoAmount,
      currencyCode: currency,
      fractionDigits: 2,
    });
    promoMessage = `${promoName} -${amount}!`;
  }

  return {
    promoMessage,
  };
};

export const hasMultiplePromoMessages = (variants: TNikonVariant[]) => {
  let totalPromosCount = 0;
  variants.forEach((variant) => {
    if (variant.price?.custom?.fields?.marketingCopy) totalPromosCount++;
    if (variant.discounts && getProductDiscountsByPosition(variant.discounts[0])?.brow) totalPromosCount++;
  });
  return totalPromosCount > 1;
};
