import { sdk } from 'sdk';

const useTest = async (): Promise<any> => {
  const testResults = await sdk.callAction({ actionName: 'test/run' });
  console.log('Environment data:', testResults);

  return { testResults };
};

export default useTest;
